import { useCallback, useEffect, useMemo, useState } from 'react';

import Lottie from 'lottie-web';

interface LottiePropsType {
  data: any; // lottie 데이터 파일
  loop?: boolean; // 반복 재생 여부
  autoplay?: boolean; // 자동재생 여부
  // speed?: number; // animation 동작속도
}

/**
 * ANCHOR: lottie 재사용 Component
 * style 및 크기는 부모 wrapper에서 제어하기
 * @param props
 * @returns
 */
export const LottieComponent = (props: LottiePropsType) => {
  const { data, loop = true, autoplay = true } = props;

  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();
  const [isMount, setIsMount] = useState<boolean>(false);

  // ANCHOR: animation 객체 형성
  const animation = useMemo(() => {
    if (!containerEl) return null;

    return Lottie.loadAnimation({
      container: containerEl, // lottie containerElement
      renderer: 'svg', // 랜더 종류 :  'svg' | 'canvas' | 'html'
      loop: loop, // 반복재생 여부
      autoplay: autoplay, // 자동재생 여부
      animationData: data, // lottie 파일(현재는 json 파일 사용)
    });
  }, [containerEl, loop, autoplay, data]);

  // ANCHOR: animationContainer Element 설정
  const handleRef = useCallback(
    (el: any) => {
      if (!el) return;
      setContainerEl(el);
    },
    [setContainerEl],
  );

  useEffect(() => {
    if (!animation) return;

    // ANCHOR: component unmount시 animation 삭제
    return () => {
      animation.destroy();
    };
  }, [animation]);

  useEffect(() => {
    setIsMount(true);
  }, []);

  if (!isMount) return null;

  return <div className={'h-full w-full'} ref={handleRef} />;
};
