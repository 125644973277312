import { indicatorState } from '@/store/indicatorStore';
import cn from 'clsx';
import { useRecoilValue } from 'recoil';
import LoadingComponent from './LoadingComponent';

const IndicatorComponent = () => {
  const isIndicator = useRecoilValue(indicatorState);

  // 돔 안그려도 됨
  if (!isIndicator) return null;

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-[1000] h-screen w-screen bg-bgPrimary',
      )}
    >
      <LoadingComponent />
    </div>
  );
};

export default IndicatorComponent;
