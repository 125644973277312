import { LottieComponent } from '../lottie';

const loading = require('public/images/common/lottie/loading.json');

const LoadingComponent = () => {
  return (
    <div className="flex min-h-[70vh] w-screen items-center justify-center">
      <div className="aspect-[100/150] w-[100px]">
        <LottieComponent data={loading} />
      </div>
    </div>
  );
};
export default LoadingComponent;
